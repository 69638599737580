<template>
  <div class="fuel-emissions">
    <!-- Gasoline Usage Card -->
    <div class="info-card gasoline-card">
      <h2><i class="fas fa-gas-pump"></i> Gasoline Usage</h2>
      <p class="emission-info">
        <i class="fas fa-calendar-day"></i> Emissions for {{ previousDate }}.
      </p>
      <p class="emission-info">
        <i class="fas fa-cloud"></i> Estimated consumption for yesterday: {{ formatNumber(Math.round(averageDailyEmissions)) }} liters.
      </p>
      <div class="highlighted-info-container">
        <p class="highlighted-info emissions">
          <i class="fas fa-smog"></i> Yesterday's estimated emissions meter
        </p>
        <div class="scale-container">
          <span class="min-value">Min</span>
          <span class="max-value">Max</span>
        </div>
        <div class="bar-container">
          <div class="emission-bar"></div>
          <div class="slider-line" :style="{ left: barWidth + '%' }"></div>
        </div>
      </div>
      <div class="carbon-emissions-container">
        <h3><i class="fas fa-cloud"></i> Carbon Emissions for {{ previousDate }}</h3>
        <p class="carbon-info">
          <i class="fas fa-gas-pump"></i> {{ formatNumber((averageDailyEmissions * 2.419 / 1000).toFixed(0)) }} tons CO<sub>2</sub>e.
        </p>
      </div>
    </div>

    <!-- Diesel Usage Card -->
    <div class="info-card diesel-card">
      <h2><i class="fas fa-truck"></i> Diesel Usage</h2>
      <p class="emission-info">
        <i class="fas fa-calendar-day"></i> Emissions for {{ previousDate }}.
      </p>
      <p class="emission-info">
        <i class="fas fa-cloud"></i> Estimated consumption for yesterday: {{ formatNumber(Math.round(averageDailyDieselEmissions)) }} liters.
      </p>
      <div class="highlighted-info-container">
        <p class="highlighted-info emissions">
          <i class="fas fa-smog"></i> Yesterday's estimated emissions meter
        </p>
        <div class="scale-container">
          <span class="min-value">Min</span>
          <span class="max-value">Max</span>
        </div>
        <div class="bar-container">
          <div class="emission-bar"></div>
          <div class="slider-line" :style="{ left: barWidthDiesel + '%' }"></div>
        </div>
      </div>
      <div class="carbon-emissions-container">
        <h3><i class="fas fa-cloud"></i> Carbon Emissions for {{ previousDate }}</h3>
        <p class="carbon-info">
          <i class="fas fa-truck"></i> {{ formatNumber((averageDailyDieselEmissions * 2.735 / 1000).toFixed(0)) }} tons CO<sub>2</sub>e.
        </p>
      </div>
    </div>

    <!-- Total Emissions Card -->
    <div class="info-card total-emissions-card">
      <h2><i class="fas fa-cloud"></i> Total Carbon Emissions Since Start of Year</h2>
      <p class="total-emission-info">
        <i class="fas fa-calendar-day"></i> From January 1 to yesterday 
      </p>
      <p class="total-emission-info">
        <i class="fas fa-smog"></i> Total Gasoline Emissions: {{ formatNumber((totalGasolineEmissions / 1000).toFixed(0)) }} tons CO<sub>2</sub>e.
      </p>
      <p class="total-emission-info">
        <i class="fas fa-truck"></i> Total Diesel Emissions: {{ formatNumber((totalDieselEmissions / 1000).toFixed(0)) }} tons CO<sub>2</sub>e.
      </p>
    </div>
    <!-- Footnote -->
    <div class="footnote">
      <p><i class="fas fa-info-circle"></i> Average Daily Consumption: Gasoline - {{ formatNumber(Math.round(averageGasolineConsumption)) }} liters, Diesel - {{ formatNumber(Math.round(averageDieselConsumption)) }} liters.</p>
      <p><i class="fas fa-info-circle"></i> Min Emissions: 252,186 liters, Max Emissions: 574,985 liters.</p>
    </div>
    <!-- Methodology Button -->
    <div class="methodology-button-container">
      <button @click="goToMethodology" class="methodology-button">
        <i class="fas fa-info-circle"></i> View Methodology
      </button>
    </div>
  </div>
  
</template>


<script>
export default {
  name: "FuelEmissions",
  data() {
    return {
      averageGasolineConsumption: 504373,
      averageDieselConsumption: 33567,
      dayFactors: {
        'Monday': 1,
        'Tuesday': 1.08,
        'Wednesday': 0.5,
        'Thursday': 1.14,
        'Friday': 0.95,
        'Saturday': 1,
        'Sunday': 0.8,
      },
      previousDate: '',
      dayFactor: 1,
      averageDailyEmissions: 0,
      averageDailyDieselEmissions: 0,
      maxEmissions: 0,
      maxEmissionsDiesel: 0,
      barWidth: 0,
      barWidthDiesel: 0,
      totalGasolineEmissions: 0,
      totalDieselEmissions: 0
    };
  },
  computed: {
    finalBarWidth() {
      return (this.averageDailyEmissions / this.maxEmissions) * 100;
    },
    finalBarWidthDiesel() {
      return (this.averageDailyDieselEmissions / this.maxEmissionsDiesel) * 100;
    }
  },
  methods: {
    calculateEmissions() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const previousDate = yesterday.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
      this.previousDate = previousDate;
      const previousDay = yesterday.toLocaleString('en-us', { weekday: 'long' });
      this.dayFactor = this.dayFactors[previousDay];
      
      this.averageDailyEmissions = this.averageGasolineConsumption * this.dayFactor;
      this.averageDailyDieselEmissions = this.averageDieselConsumption * this.dayFactor;
      
      this.maxEmissions = this.averageGasolineConsumption * this.dayFactors['Thursday']; // Assuming Thursday has the maximum factor
      this.maxEmissionsDiesel = this.averageDieselConsumption * this.dayFactors['Thursday'];
      
      this.calculateTotalEmissions();
    },
    calculateTotalEmissions() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const daysInYear = Math.floor((yesterday - startOfYear) / (1000 * 60 * 60 * 24)) + 1;
      this.totalGasolineEmissions = this.calculateCumulativeEmissions(daysInYear, this.averageGasolineConsumption, 2.419);
      this.totalDieselEmissions = this.calculateCumulativeEmissions(daysInYear, this.averageDieselConsumption, 2.735);
    },
    calculateCumulativeEmissions(days, averageConsumption, emissionFactor) {
      let totalEmissions = 0;
      for (let i = 0; i < days; i++) {
        const dayOfWeek = new Date(new Date().setDate(i + 1)).toLocaleString('en-us', { weekday: 'long' });
        const dayFactor = this.dayFactors[dayOfWeek];
        totalEmissions += averageConsumption * dayFactor * emissionFactor;
      }
      return totalEmissions;
    },
    animateBar() {
      this.barWidth = 0;  // Start from 0%
      this.barWidthDiesel = 0;  // Start from 0%
      setTimeout(() => {
        this.barWidth = this.finalBarWidth;
        this.barWidthDiesel = this.finalBarWidthDiesel;
      }, 100);  // Timeout to trigger the transition
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    goToMethodology() {
      this.$router.push({ path: 'methodology' });
    }
  },
  mounted() {
    this.calculateEmissions();
    this.$nextTick(() => {
      this.animateBar();
    });
  }
};
</script>

<style scoped>
.fuel-emissions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.info-card {
  flex: 1 1 45%;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gasoline-card {
  border-left: 8px solid #ff5722;
}

.diesel-card {
  border-left: 8px solid #1976d2;
}

.total-emissions-card {
  border-left: 8px solid #d32f2f;
}

.emission-info, .total-emission-info {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.highlighted-info-container {
  margin-top: 20px;
}

.highlighted-info {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 10px;
}

.scale-container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  padding: 0 5px;
}

.min-value {
  color: green;
}

.max-value {
  color: red;
}

.bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  height: 30px;
  position: relative;
}

.emission-bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, green 0%, yellow 20%, red 90%);
  border-radius: 5px;
}

.slider-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: black;
  transition: left 2s ease-in-out;
}

.carbon-emissions-container {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffebee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carbon-emissions-container h3 {
  font-size: 22px;
  color: #d32f2f;
}

.carbon-info {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: #d32f2f;
}

.footnote {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.footnote p {
  font-size: 16px;
  font-weight: bold;
}
/* Methodology Button Styles */
.methodology-button-container {
  text-align: center;
  margin-top: 20px;
}

.methodology-button {
  background-color: #1976d2;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.methodology-button i {
  margin-right: 8px;
}

.methodology-button:hover {
  background-color: #0d47a1;
}
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');

@media (max-width: 600px) {
  .info-card, .fuel-emissions {
    padding: 10px;
  }

  .emission-info, .total-emission-info {
    font-size: 16px;
  }

  .highlighted-info-container {
    flex-direction: column;
  }

  .highlighted-info {
    margin: 5px 0;
  }

  .carbon-emissions-container {
    padding: 10px;
  }

  .carbon-info {
    font-size: 16px;
  }

  .info-card {
    flex: 1 1 100%;
  }

  .footnote p {
    font-size: 14px;
  }
}
</style>
